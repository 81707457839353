import { type IPropertyLeasePortfolioItem, SortColumn } from '@portfolio'
import { createColumnHelper } from '@tanstack/vue-table'
import { breakpointsTailwind } from '@vueuse/core'
import { Product, ProductScope } from '~/data-services/gen'

export function useLeaseTable(
  productScope: MaybeRefOrGetter<ProductScope> = ProductScope.ALL,
) {
  const { isLandlord, isTenant, hasProduct } = useAuth()
  const dayjs = useDayjs()
  const columnHelper = createColumnHelper<IPropertyLeasePortfolioItem>()
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const greaterOrEqualMd = breakpoints.greaterOrEqual('md')
  const route = useRoute('division-companyId-portfolio')

  const showSnippetCol = computed(() => {
    // @ts-expect-error
    return route.query?.filters?.advanced?.mode === 'hasFields'
  })

  const columns = computed(() => [
    columnHelper.accessor('name', {
      id: SortColumn.NAME,
      header: 'Lease name',
      // size: greaterOrEqualMd.value ? 380 : 280,
      // minSize: 260,
      // maxSize: 380,
      meta: {
        fixed: () => greaterOrEqualMd.value && 'left',
      },
    }),

    columnHelper.display({
      id: 'snippet',
      header: 'Advanced',
      minSize: 300,
      size: 300,
      meta: {
        get visible() {
          return showSnippetCol.value
        },
      },
    }),

    columnHelper.accessor('status', {
      id: SortColumn.STATUS,
      header: 'Status',
    }),

    columnHelper.accessor('commencementDate', {
      id: SortColumn.COMMENCEMENT_DATE,
      header: 'Commencement',
      // size: 110,
      cell: ({ getValue }) => {
        // TODO: Can't get the date format from lease from IPropertyLeasePortfolioItem
        const commencementDate = getValue()
        return commencementDate
          ? dayjs(commencementDate).format(getLeaseDateFormat())
          : undefined
      },
    }),
    columnHelper.accessor('expiryDate', {
      id: SortColumn.EXPIRY_DATE,
      header: 'Expiry',
      // size: 90,
      cell: ({ getValue }) => {
        // TODO: Can't get the date format from lease from IPropertyLeasePortfolioItem
        const expiryDate = getValue()
        return expiryDate
          ? dayjs(expiryDate).format(getLeaseDateFormat())
          : undefined
      },
    }),
    columnHelper.accessor(
      (row) => {
        if (isLandlord.value) return row.lessees
        if (isTenant.value) return row.lessors
      },
      {
        id: isLandlord.value
          ? SortColumn.LESSEE
          : isTenant.value
            ? SortColumn.LESSOR
            : 'lesseeOrLessor',
        header: () => {
          if (isLandlord.value) return 'Lessee'
          if (isTenant.value) return 'Lessor'
        },
        cell: ({ getValue }) => getValue()?.join(', '),
        enableSorting: isLandlord.value || isTenant.value,
        // size: greaterOrEqualMd.value ? 330 : 280,
        // maxSize: 330,
        meta: {
          visible: isLandlord.value || isTenant.value,
        },
      },
    ),

    columnHelper.accessor('state', {
      id: SortColumn.STATE,
      header: 'State',
      // size: 40,
    }),

    columnHelper.accessor('size', {
      id: SortColumn.SIZE,
      header: 'Area',
      // size: 40,
      cell: ({ getValue }) => {
        const val = getValue()
        return val ?? '-'
      },
    }),

    columnHelper.accessor('updatedAt', {
      id: SortColumn.UPDATED_AT,
      header: 'Updated at',
      size: 120,
      cell: ({ getValue }) => {
        const updatedAt = getValue()
        return updatedAt
          ? `${dayjs(updatedAt).format(getLeaseDateFormat())}`
          : undefined
      },
    }),

    columnHelper.accessor('completion', {
      id: SortColumn.COMPLETENESS,
      header: 'Fields',
      // size: 50,
      meta: {
        visible: () =>
          !hasProduct(Product.LEASE_MANAGER) ||
          toValue(productScope) === ProductScope.LEASE_REGISTER,
      },
    }),

    columnHelper.accessor('approvedVariationCount', {
      id: 'approvedVariationCount',
      header: 'Amendments',
      // size: 110,
      meta: {
        visible: () =>
          !hasProduct(Product.LEASE_MANAGER) ||
          toValue(productScope) === ProductScope.LEASE_REGISTER,
      },
    }),

    columnHelper.display({
      id: 'actions',
      // size: 100,
      meta: {
        fixed: () => greaterOrEqualMd.value && 'right',
      },
    }),
  ])

  return { columns }
}
