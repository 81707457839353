<script lang="ts" setup>
import { twMerge } from 'tailwind-merge'
import type { Table } from '#components'
import { exportLeaseToXlsx } from '~/composables/useAPI/useApiExport'
import type { IPagination } from '@shared/composables/usePagination'
import { leaseStatusObject } from '~/constants/LeaseStatus'
import { useLeaseTable } from './composables/useLeaseTable'
import type { IPropertyLeasePortfolioItem } from '@portfolio'
import type { TanstackTableColumn } from '@ui/components/TanstackTable'
import type { IImportDocumentToReview } from '@workflow'
import type { SortingState } from '@tanstack/vue-table'
import { useModal } from 'vue-final-modal'
import ModalSnippetsValues from './ModalSnippetsValues.vue'
import { Product, ProductScope } from '~/data-services/gen'

import 'floating-vue/dist/style.css'

interface Props {
  data: IPropertyLeasePortfolioItem[]
  loading?: boolean
  pagination?: IPagination
  total?: number
  selectedRows?: IPropertyLeasePortfolioItem[]
  sort?: SortingState
  productScope?: ProductScope
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  pagination: () => ({ page: 1, pageSize: 25 }),
  total: 0,
  selectedRows: () => [],
  sort: () => [],
  productScope: ProductScope.ALL,
})

const emit = defineEmits<{
  (e: 'update:pagination', value: IPagination): void
  (e: 'update:selectedRows', payload: IImportDocumentToReview[]): void
  (e: 'update:sort', sorting: SortingState): void
}>()

const selectedRows = useVModel(props, 'selectedRows', emit)
const pagination = useVModel(props, 'pagination', emit)

const router = useRouter()
const route = useRoute('division-companyId-portfolio')
const companyId = route.params.companyId

/* === TABLE === */
const tableRef = ref<InstanceType<typeof Table>>()
const { columns } = useLeaseTable(() => props.productScope)
/* === TABLE === */

/* === FUNCTIONS === */
const { createDivisionURL } = useDivisions()
const onExportSingle = ({ leaseId, name }: IPropertyLeasePortfolioItem) => {
  if (!leaseId || !name) return

  return exportLeaseToXlsx(companyId, leaseId, name)
}
/* === FUNCTIONS === */

function makeAddress({
  address,
  city,
  state,
  country,
}: {
  address?: string | null
  city?: string | null
  state?: string | null
  country?: string | null
}) {
  if (!state) return 'N/A'
  return `${address}, ${city}, ${fullStateToAbbreviation(state)} ${country}`
    .trim()
    .replaceAll(' ,', ',')
}

function getStatusColor(status: string): string {
  const statusColor =
    leaseStatusObject[status as keyof typeof leaseStatusObject]?.color
  return twMerge([
    statusColor,
    'whitespace-nowrap rounded-full text-[12px] border px-2.5 py-1 !bg-opacity-10 border-opacity-60',
  ])
}

const sort = useVModel(props, 'sort', emit)
const googleApiKey = useRuntimeConfig().public.googleApiKey

type LatLng = number | null | undefined
type LatLngTuple = {
  latitude: LatLng
  longitude: LatLng
}

const googleMapAddress = (latLng: LatLngTuple) => {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${latLng.latitude},${latLng.longitude}&markers=color:red%7C${latLng.latitude},${latLng.longitude}&format=png&&zoom=14&scale=2&size=400x250&key=${googleApiKey}&mapId=4162bfc8ea6f3177`
}

const lastSelectedSnippetId = ref('')
const { open, close } = useModal({
  component: ModalSnippetsValues,

  attrs: {
    leaseId: lastSelectedSnippetId,
    onClose() {
      close()
    },
  },
})

const openSnippet = (leaseId: string) => {
  lastSelectedSnippetId.value = leaseId
  open()
}

const onClickRow = ({ products, leaseId }: IPropertyLeasePortfolioItem) => {
  if (props.productScope === ProductScope.LEASE_REGISTER) {
    return router.push(
      `/division/${companyId}/lease/${leaseId}?product=${ProductScope.LEASE_REGISTER}`,
    )
  }

  if (products?.includes(Product.LEASE_MANAGER)) {
    return router.push(`/division/${companyId}/lease/${leaseId}/manager`)
  }

  if (products?.includes(Product.LEASE_REGISTER)) {
    return router.push(`/division/${companyId}/lease/${leaseId}/lease`)
  }
}
</script>

<template>
  <DataTable
    ref="tableRef"
    v-model:pagination="pagination"
    v-model:selection="selectedRows"
    v-model:sort="sort"
    sortable="manual"
    :columns="columns"
    :data="data"
    :loading="loading"
    :total-records="total"
    :sticky="{ offsetHeader: 56 }"
    clickable
    aria-describedby="Lease portfolio table"
    @click:row="onClickRow"
  >
    <template #empty>
      <div class="mt-8 flex flex-col items-center justify-center pb-12 pt-8">
        <h1>No leases found</h1>

        <Icon name="no-lease" class="my-8 text-8xl text-gray-200" />

        <p>
          <Button color="primary" :to="`/division/${companyId}/workflow`">
            Review and add a lease to portfolio
          </Button>
        </p>
      </div>
    </template>

    <!-- lease name -->
    <template
      #item-name="{
        item: { name, leaseId },
      }: TanstackTableColumn<IPropertyLeasePortfolioItem>"
    >
      <span
        class="group-hover/tr:text-primary relative line-clamp-2 min-w-[260px] max-w-[400px]"
      >
        {{ name ?? '-' }}
      </span>
    </template>

    <!-- lease name -->
    <template
      #item-snippet="{
        item: { leaseId },
      }: TanstackTableColumn<IPropertyLeasePortfolioItem>"
    >
      <span class="flex w-full justify-center pr-1">
        <Button
          class="ring-primary group-hover/tr:bg-primary flex h-8 w-8 items-center justify-center rounded-lg ring-offset-2 ring-offset-gray-800 hover:cursor-pointer hover:text-black hover:ring-[1px] group-hover/tr:text-black"
          color="opacityPrimary"
          @click="openSnippet(leaseId)"
        >
          <Icon name="document-search" class="group-hover/tr:text-xl" />
        </Button>
      </span>
    </template>

    <!-- status -->
    <template
      #item-status="{
        value: status,
      }: TanstackTableColumn<IPropertyLeasePortfolioItem>"
    >
      <span :class="getStatusColor(status)">
        {{ status ? localizeLeaseStatus(status) : '-' }}
      </span>
    </template>

    <!-- commencementDate -->
    <template
      #item-commencementDate="{
        value: commencementDate,
      }: TanstackTableColumn<IPropertyLeasePortfolioItem>"
    >
      <time class="whitespace-nowrap">{{ commencementDate ?? '-' }}</time>
    </template>

    <!-- expiryDate -->
    <template
      #item-expiryDate="{
        value: expiryDate,
      }: TanstackTableColumn<IPropertyLeasePortfolioItem>"
    >
      <time class="whitespace-nowrap">{{ expiryDate ?? '-' }}</time>
    </template>

    <!-- lessee -->
    <template
      #item-lessee="{ value }: TanstackTableColumn<IPropertyLeasePortfolioItem>"
    >
      <VTooltip
        class="inline-flex"
        :delay="10"
        placement="top"
        :distance="12"
        :auto-hide="true"
      >
        <span class="line-clamp-2 max-w-[350px]">
          {{ value ?? '-' }}
        </span>

        <template #popper>
          <div class="text-xs">{{ value ?? '-' }}</div>
        </template>
      </VTooltip>
    </template>

    <!-- lessor -->
    <template
      #item-lessor="{ value }: TanstackTableColumn<IPropertyLeasePortfolioItem>"
    >
      <VTooltip
        class="inline-flex"
        :delay="10"
        placement="top"
        :distance="12"
        :auto-hide="true"
      >
        <span class="line-clamp-2 max-w-[350px]">
          {{ value ?? '-' }}
        </span>

        <template #popper>
          <div class="text-xs">{{ value ?? '-' }}</div>
        </template>
      </VTooltip>
    </template>

    <!-- state -->
    <template
      #item-state="{
        item: { state, latitude, longitude, googleAddress },
      }: TanstackTableColumn<IPropertyLeasePortfolioItem>"
    >
      <div v-if="state">
        <VTooltip class="w-full" :delay="100" :distance="8" :auto-hide="false">
          <span class="cursor-help">
            {{ fullStateToAbbreviation(state) }}
          </span>
          <template #popper>
            <div class="flex flex-col gap-2">
              {{ googleAddress }}
              <img
                v-if="latitude && longitude"
                class="bg-gray-750 h-[250px] w-[400px] overflow-hidden rounded border border-gray-700"
                :src="
                  googleMapAddress({
                    latitude,
                    longitude,
                  })
                "
                alt="address"
              />
              <span v-else class="block">
                Could not find the address on Google Maps
              </span>
            </div>
          </template>
        </VTooltip>
      </div>
      <span v-else> N/A </span>
    </template>

    <!-- updatedAt -->
    <template
      #item-updatedAt="{
        value: updatedAt,
      }: TanstackTableColumn<IPropertyLeasePortfolioItem>"
    >
      <time class="whitespace-nowrap">
        {{ updatedAt }}
      </time>
    </template>

    <!-- size -->
    <template
      #item-size="{
        value: size,
      }: TanstackTableColumn<IPropertyLeasePortfolioItem>"
    >
      {{ size }}
    </template>

    <!-- completeness -->
    <template
      #item-completeness="{
        value: completion,
      }: TanstackTableColumn<IPropertyLeasePortfolioItem>"
    >
      <div class="flex w-14 items-center justify-center">
        <template v-if="completion">
          <LeaseCompletion
            v-if="!!completion.approvedFields && !!completion.totalFields"
            :title="`${completion?.approvedFields}/${completion?.totalFields}`"
            :force-show="true"
            :progress="
              (completion.approvedFields / completion.totalFields) * 100
            "
          />
        </template>
        <span v-else> - </span>
      </div>
    </template>

    <template
      #item-approvedVariationCount="{
        item: { variationCount, approvedVariationCount },
      }"
    >
      <div class="flex w-14 items-center">
        <template v-if="variationCount > 0">
          <LeaseCompletion
            :title="`${approvedVariationCount}/${variationCount}`"
            :force-show="true"
            type="amendments"
            :progress="(approvedVariationCount / variationCount) * 100"
          />
        </template>
        <span v-else> - </span>
      </div>
    </template>

    <!-- actions -->
    <template
      #item-actions="{
        item: lease,
      }: TanstackTableColumn<IPropertyLeasePortfolioItem>"
    >
      <div class="flex w-full items-center justify-end gap-2">
        <!-- AI -->
        <Button
          v-if="
            productScope !== ProductScope.LEASE_REGISTER &&
            lease.products?.includes(Product.LEASE_REGISTER) &&
            $can('product', Product.LEASE_REGISTER)
          "
          color="transparentSecondary"
          class="hover:!bg-primary group-hover/tr:bg-gray-650 h-8 w-8 px-0 hover:text-black"
          :to="`/division/${companyId}/lease/${lease.leaseId}/lease?product=${ProductScope.LEASE_REGISTER}`"
          @click.stop="() => {}"
        >
          AI
        </Button>

        <!-- Download button -->
        <VTooltip
          class="inline-flex"
          :delay="10"
          placement="top"
          :distance="12"
          :auto-hide="true"
        >
          <Button
            :id="`${lease.leaseId}-export`"
            color="transparentSecondary"
            class="hover:!bg-primary group-hover/tr:bg-gray-650 h-8 w-8 px-0 hover:text-black"
            icon="download-arrow"
            @click.stop="onExportSingle(lease)"
          />

          <template #popper>
            <div class="text-xs">Download this lease in xls</div>
          </template>
        </VTooltip>

        <!-- Documents button -->
        <VTooltip
          class="inline-flex"
          :delay="10"
          placement="top"
          :distance="12"
          :auto-hide="true"
        >
          <Button
            :id="`${lease.leaseId}-documents`"
            color="transparentSecondary"
            class="hover:!bg-primary group-hover/tr:bg-gray-650 h-8 w-12 px-0 hover:text-black"
            icon="document-do-list"
            :to="`/division/${companyId}/lease/${lease.leaseId}/documents`"
            @click.stop="() => {}"
          >
            <span class="text-gray-450 text-[10px]">
              ({{ lease.documentCount }})
            </span>
          </Button>

          <template #popper>
            <div class="text-xs">Documents</div>
          </template>
        </VTooltip>

        <!-- Edit button -->
        <Button
          v-if="productScope === ProductScope.LEASE_REGISTER"
          color="transparentSecondary"
          icon="edit"
          :to="`/division/${companyId}/lease/${lease.leaseId}/lease?product=${ProductScope.LEASE_REGISTER}`"
          class="hover:!bg-primary group-hover/tr:bg-gray-650 h-8 px-2.5 hover:text-black"
          @click.stop="() => {}"
        >
          Review
        </Button>
      </div>
    </template>
  </DataTable>
</template>
